.searchTypeTabs {
    align-self: flex-end;
    margin-left: 14px;
}

.mobileSearchTypeTabs {
    margin-bottom: 8px;
}

.menuWrapper {
    display: flex;
    align-items: center;
    height: 48px;
}

.mobileMenuWrapper {
    height: 55px;
}

.menu {
    margin-left: auto;
    z-index: 4;
}

.logo {
    padding-left: 3px;
}