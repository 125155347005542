.burger {
    width: 24px;
    height: 24px;

    position: absolute;
    right: 8px;
    bottom: 12px;
}

.burger>path {
    stroke: #c2c5cc;
}