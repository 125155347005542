.title {
  font-size: 16px;
  line-height: 20px;
  font-weight: bold;
  color: #919499;
  margin-bottom: 8px;
}

.goToStoresContainer {
  display: flex;
  flex-wrap: wrap;
  grid-gap: 4px;
  gap: 4px;
}
