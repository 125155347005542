.footerLogos {
  display: flex;
  grid-gap: 16px;
  gap: 16px;
  margin-bottom: 8px;
  height: 47px;
  width: 47px;
  > svg {
    overflow: visible;
  }
}

.footerLogo {
  height: 42px;
  width: 50px;
}
