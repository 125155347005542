.container {
    position: absolute;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
}

.sidebarWrapper {
    position: fixed;
    z-index: 1001;
    top: 0;
    left: -210px;
    width: 210px;
    height: 100vh;
    transition: all 200ms cubic-bezier(0.25, 0.25, 0.75, 0.75);

}

.openedSidebar {
    transform: translate3d(210px, 0px, 0px)
}

.image {
    height: 147px;
    background: url('https://jsapi.cdn.level.travel/lt-modules-assets/search/mobile_menu_image@2x.jpg') -40px -80px no-repeat;
    background-size: 362px 251px;
}

.menuWrapper {
    /* вычитаем из 100% высоту изображения, которое в шапке */
    height: calc(100dvh - 147px);
    background: #f5f5f5;
    box-shadow: 0px -0.5px 3.5px 0px rgba(0, 0, 0, 0.28);
}

.menuTopPart {
    position: relative;
    padding-top: 15.5px;
    height: 100%;
    overflow: scroll;
}

.menuLink {
    padding: 10px 25px;
    display: flex;
    grid-gap: 8px;
    gap: 8px;
    align-items: center;
    text-decoration: none;
    color: #000;
}

.menuItem {
    padding: 10px 25px;
    grid-gap: 8px;
    gap: 8px;
    align-items: center;
    display: flex;
    text-decoration: none;
    color: #000;
}

.icon {
    min-width: 24px;
    height: 24px;
}

.menuText {
    display: inline-block;
    font-size: 14px;
    vertical-align: middle;
    color: #000;
}

.fullVersionContainer {
    border-top: 1px solid #dbdbdb;
    padding: 5px 0px;
}

.bottomLink {
    color: rgb(124, 136, 161);
}

.mobileStoreContainer {
    position: relative;
    display: flex;
    width: 100%;
    grid-gap: 5px;
    gap: 5px;
    flex-direction: column;
    align-items: center;
}

.sideNavBg {
    background: rgba(0, 0, 0, 0.5);
    position: fixed;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    z-index: 1000;
}