.buttonDesktop {
    width: 181px !important;
    height: 40px !important;
}

.buttonMobile {
    width: 288px !important;
    height: 38px !important;
}

.containerDesktop {
    display: flex;
    flex-direction: column;
    margin: auto;
    align-items: center;
    justify-content: center;
    margin-top: 20px;
}

.containerMobile {
    display: flex;
    flex-direction: column;
    margin: auto;
    align-items: center;
    justify-content: space-between;
    padding-top: 16px;
    width: 100%;
    margin-bottom: 37px;
    min-height: calc(100vh - 37px - 74px);
    height: -webkit-fill-available;
}

.containerDesktop svg, .containerMobile svg {
    stroke: none !important;
}

.text {
    font-size: 16px;
    font-weight: 400;
    line-height: 20px;
    text-align: center;
    color: #4D4D4D;
    margin-top: 8px;
    margin-bottom: 16px;
}

.title {
    font-size: 18px;
    font-weight: 700;
    line-height: 22px;
    margin-bottom: 52px;
}

.wrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 292px;
}

.childrenContainer {
    width: 100%;
    opacity: 0.3;
    -webkit-mask-image: linear-gradient(180deg, rgba(255, 255, 255, 1) 0%, rgba(255, 255, 255, 0.9) 20%, transparent 100%);
    mask-image: linear-gradient(180deg, rgba(255, 255, 255, 1) 0%, rgba(255, 255, 255, 0.9) 20%, transparent 100%);
    pointer-events: none;
}

.cardWithButtonWrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    justify-content: flex-end;
}