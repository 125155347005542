.title {
  font-size: 16px;
  line-height: 20px;
  font-weight: bold;
  color: #919499;
  margin-bottom: 8px;

  @media screen and (min-width: 992px) {
    text-align: right;
  }
}

.linksContainer {
  display: flex;
  grid-gap: 1px;
  gap: 1px;
  border-radius: 4px;
  border-radius: 8px;
  overflow: hidden;
  isolation: isolate;
  width: -webkit-min-content;
  width: min-content;
}

.linkWrapper {
  width: 47px;
  height: 47px;
  display: flex;
  align-items: center;
  justify-content: center;
  background: #4d4d4d;
  transition: background-color 0.3s ease-in-out;
}

.vkLink:hover {
  background-color: #45668e;
}

.tgLink:hover {
  background-color: #0088cc;
}

.youtubeLink:hover {
  background-color: #cd201f;
}
