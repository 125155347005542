.topSection {
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: 1fr;

  grid-gap: 16px;

  gap: 16px;


  @media screen and (min-width: 630px) {
    grid-template-columns: 1fr 1fr 1fr;
  }

  @media (min-width: 1200px) {
    grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
  }
  a {
    color: #919499;
  }
}
