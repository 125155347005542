.bottomSection {
  font-size: 12px;
  line-height: 16px;

  border-radius: 16px;
  background: #17181a;
  padding: 16px;

  color: #919499;
}

.legalInfo {
  display: grid;
  grid-template-columns: auto;
  grid-gap: 16px;
  gap: 16px;

  @media screen and (min-width: 1200px) {
    grid-template-columns: 1fr 1fr;
  }
}

.legalLinks {
  display: flex;
  flex-direction: column;
  grid-gap: 4px;
  gap: 4px;
}

.legalLink {
  color: #e6e9ee !important;
}

.rightContainer {
  display: flex;
  flex-direction: column;
  grid-gap: 8px;
  gap: 8px;
}

.linksSection {
  display: flex;
  flex-direction: column;
  grid-gap: 6px;
  gap: 6px;
}
