.searchWidgetPreview {
    margin-right: auto;
}

.widgetWrapper {
    width: 100%;
    background-color: #121212;
}

.widgetWrapper :global .lt-search-form.expanded .search-form-wrapper {
    position: relative;
}