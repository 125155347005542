.contentHeadingContainer {
    width: 100%;
    height: 40px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    color: #fff;
}

.contentHeadingFirstRow {
    width: 100%;
    display: flex;
    justify-content: space-between;
    font-size: 18px;
    line-height: 22px; 
}

.contentHeadingSecondRow {
    font-size: 12px;
    line-height: 16px;
}

.countryName {
    font-weight: 700;
}