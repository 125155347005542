.searchFormMobile {
  z-index: 3;
  padding: 0px;
  position: relative;
}

.searchTabs {
  position: absolute;
  top: -48px;
}

.searchTabsMobile {
  z-index: 1;
  margin-bottom: 1em;
}
