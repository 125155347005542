.footer {
  padding: 32px 20px;
  background-color: #333;
  display: flex;
  justify-content: center;
  width: 100%;
}

.footerContent {
  display: flex;
  flex-direction: column;
  grid-gap: 16px;
  gap: 16px;
  max-width: 1200px;
}

.hr {
  border: none;
  border-top: 1px solid #4d4d4d;
  width: 100%;
}
