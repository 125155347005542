.headerMenu {
    padding-top: 20px;
}

.homepageServices:hover {
    border-radius: 3px 3px 0 0;
    color: #007aff;
}

.homepageServices:hover svg {
    stroke: #007aff;
}

.services:hover {
    background-color: #292929;
    border-radius: 3px 3px 0 0;
    color: #fff;
}

.services:hover svg {
    stroke: #fff;
}

.fullServices ul {
    right: inherit;
    right: 0;
    left: auto;
}

.homepageHelp:hover {
    border-radius: 3px 3px 0 0;
    color: #007aff;
}

.homepageHelp:hover svg {
    stroke: #007aff;
}

.help:hover {
    background-color: #292929;
    border-radius: 3px 3px 0 0;
    color: #fff;
}

.help:hover svg {
    stroke: #fff;
}

.homepageFeedback:hover {
    border-radius: 3px 3px 0 0;
    color: #007aff;
}

.homepageFeedback:hover svg {
    stroke: #007aff;
    fill: #007aff;
}