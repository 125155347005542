.link {
    color: #17181a !important;
    display: block;
}

.textOverflow {
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
}

.hotelName {
    margin-top: 4px;
    font-size: 16px;
    font-weight: 700;
    line-height: 20px;
    composes: textOverflow;
}

.hotelPlace {
    composes: textOverflow;
    font-size: 12px;
}

.hotelPrice {
    font-weight: 700;
    margin-top: 4px;
}

.currencyFormat {
    font-size: 18px;
    line-height: 22px;
    display: block;
    margin: 4px 0;
}

.searchParamsText {
    font-size: 12px;
    color: #4d4d4d; 
}