.logoWrapper {
    position: absolute;
    top: 3px;
    width: 100%;
    right: 0;
    display: flex;
    justify-content: center;
    height: 40px;
}

.widgetLtLogo {
    height: 40px;
    margin-top: 10px;
}

.mobileLtLogo {
    width: 30px;
    margin: 0 10px 0 10px;
    padding-top: 23.5px;
}

.defaultLtLogo {
    padding-top: 24px;
}