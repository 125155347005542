.number {
    padding: 2px 6px 4px 6px;
    border-radius: 24px;
    background: #e6e9ee;
    font-size: 12px;
    margin-left: 5px;
}

.wishListHeart {
    width: 24px;
}