.title {
  font-size: 16px;
  line-height: 20px;
  font-weight: bold;

  color: #919499;
  margin-bottom: 8px;
}

.list {
  display: flex;
  flex-wrap: wrap;
  grid-gap: 4px 16px;
  gap: 4px 16px;
  font-size: 14px;

  @media screen and (min-width: 768px) {
    flex-direction: column;
  }
}

.list li a {
  color: #919499 !important;
  margin-bottom: 6px;
}

.list li {
  margin-bottom: 6px;
  height: 14px;
}
