.middleSection {
  display: flex;
  grid-gap: 8px 32px;
  gap: 8px 32px;
  flex-direction: column;
  flex-wrap: wrap;

  @media screen and (min-width: 992px) {
    flex-direction: row;
    justify-content: space-between;
  }
}
