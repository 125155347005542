.header {
  display: flex;
  z-index: 1000;
  top: 0;
  right: 0;
  left: 0;
  min-height: 82px;
  padding: 6px 0px;
  color: #fff;
  align-items: center;
  transform: translateZ(0);
}

@media screen and (max-width: 1220px) {
  .header {
    padding-left: 16px;
  }

  .wlHeader {
    padding-left: 0px;
  }
}

.mobileHeader {
  min-height: 74px;
  padding: 4px;
}

.stickyHeader {
  position: -webkit-sticky;
  position: sticky;
}

.isOpenedForm {
  align-items: stretch;
  flex-direction: column;
  padding: 8px 16px 16px;
}

.portalledHeader {
  position: fixed !important;
  top: 0px;
}

.menu {
  margin-left: auto;
  color: #fff !important;
}

.menu :global .messanger_svg__menu-messanger {
  fill: #fff;
}

.menuWithBlackColor {
  color: #292929 !important;
}

.menuWithBlackColor svg {
  color: #292929;
  stroke: #292929;
}

.menuWithBlackColor :global .messanger_svg__menu-messanger {
  fill: #292929;
}

.widgetWrapper {
  width: 100%;
  background-color: #121212;
  padding: 8px;
  position: relative;
  z-index: 1004;
}
